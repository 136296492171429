var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state.isBETA
        ? _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                _c("h2", { staticClass: "elementSubTitle" }, [
                  _vm._v("Box plot doorlooptijd")
                ])
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { "slider-color": "secondary" },
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _vm._l(_vm.schedules, function(schedule, key) {
                        return [
                          _c(
                            "v-tab",
                            {
                              key: "planning-checker-" + key,
                              staticClass: "tab__filled",
                              class: { active: _vm.activeTab === key },
                              attrs: { href: "#" + key }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(key) +
                                  " (" +
                                  _vm._s(_vm.schedules[key].length) +
                                  ") "
                              )
                            ]
                          )
                        ]
                      }),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.activeTab,
                            callback: function($$v) {
                              _vm.activeTab = $$v
                            },
                            expression: "activeTab"
                          }
                        },
                        [
                          _vm._l(_vm.schedules, function(schedule, keyName) {
                            return [
                              _c(
                                "v-tab-item",
                                {
                                  key:
                                    "planning-checker-organizations-" + keyName,
                                  attrs: { lazy: true, value: keyName }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementPanel elementPanel--spaced fill-height"
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "py-2": "",
                                            "align-end": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "custom-filter",
                                              attrs: { shrink: "" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "with-border",
                                                attrs: {
                                                  "append-icon": "search",
                                                  placeholder:
                                                    "Zoek naar rapport",
                                                  "single-line": "",
                                                  "hide-details": "",
                                                  clearable: ""
                                                },
                                                on: {
                                                  input: _vm.searchDebounce
                                                },
                                                model: {
                                                  value: _vm.searchInput,
                                                  callback: function($$v) {
                                                    _vm.searchInput = $$v
                                                  },
                                                  expression: "searchInput"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "custom-filter",
                                              attrs: { shrink: "" }
                                            },
                                            [
                                              _c("v-autocomplete", {
                                                staticClass: "with-border",
                                                attrs: {
                                                  items: _vm.reportStatusMap,
                                                  "item-text": "label",
                                                  "item-value": "key",
                                                  "hide-details": "",
                                                  placeholder: "Status",
                                                  multiple: "",
                                                  clearable: ""
                                                },
                                                on: {
                                                  change: _vm.getOrganizations
                                                },
                                                model: {
                                                  value: _vm.selectedStatuses,
                                                  callback: function($$v) {
                                                    _vm.selectedStatuses = $$v
                                                  },
                                                  expression: "selectedStatuses"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { shrink: "" } },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  ref: "dialog",
                                                  refInFor: true,
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    lazy: "",
                                                    "nudge-right": "100",
                                                    "full-width": "",
                                                    "max-width": "290px",
                                                    "min-width": "290px"
                                                  },
                                                  model: {
                                                    value: _vm.isEditingDate,
                                                    callback: function($$v) {
                                                      _vm.isEditingDate = $$v
                                                    },
                                                    expression: "isEditingDate"
                                                  }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "input input-date mt-3",
                                                    attrs: {
                                                      slot: "activator",
                                                      label: "Filter op datum",
                                                      "error-messages":
                                                        _vm.dateErrorMessage,
                                                      "hide-details": ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.dateFromTextField(
                                                          $event
                                                        )
                                                      }
                                                    },
                                                    slot: "activator",
                                                    model: {
                                                      value: _vm.dateFormatted,
                                                      callback: function($$v) {
                                                        _vm.dateFormatted = $$v
                                                      },
                                                      expression:
                                                        "dateFormatted"
                                                    }
                                                  }),
                                                  _c("v-date-picker", {
                                                    ref: "picker",
                                                    refInFor: true,
                                                    attrs: {
                                                      locale: "nl-nl",
                                                      min: "1910-01-01",
                                                      color: "#837f16",
                                                      "first-day-of-week": "1"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        _vm.isEditingDate = false
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.date,
                                                      callback: function($$v) {
                                                        _vm.date = $$v
                                                      },
                                                      expression: "date"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.isLoading
                                        ? _c("LoaderCard", {
                                            attrs: {
                                              type: "spinner--center",
                                              minHeight: "400px"
                                            }
                                          })
                                        : _vm._e(),
                                      !_vm.isLoading && schedule
                                        ? [
                                            _vm.schedules[keyName].length
                                              ? _c(
                                                  "v-layout",
                                                  {
                                                    staticClass:
                                                      "item__list item__list--reports",
                                                    attrs: {
                                                      wrap: "",
                                                      "mb-5": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass: "label",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: { wrap: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs4: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Status"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Aantal"
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._l(
                                                      _vm.foundSchedules(),
                                                      function(
                                                        foundSchedule,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "v-flex",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs4: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    foundSchedule.status
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            domProps: {
                                                                              innerHTML: _vm._s(
                                                                                _vm.getStatusPill(
                                                                                  foundSchedule.status
                                                                                )
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs2: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getStatusCount(
                                                                            foundSchedule.status
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm.schedules[keyName].length
                                              ? _c(
                                                  "v-layout",
                                                  {
                                                    staticClass:
                                                      "item__list item__list--reports",
                                                    attrs: { wrap: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass: "label",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: { wrap: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Datum"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v("Tijd")
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Rapportnummer"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Expert naam"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs1: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Aantal schades"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Status"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs1: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Intrekken"
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._l(
                                                      _vm.schedules[keyName],
                                                      function(
                                                        schedule,
                                                        keyBe
                                                      ) {
                                                        return [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              key: keyBe,
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      schedule.planned_at
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatDate"
                                                                                  )(
                                                                                    schedule.planned_at
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      schedule.planned_at
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatTime"
                                                                                  )(
                                                                                    schedule.planned_at
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              schedule.case_number
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              schedule.expert_name
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              schedule.damage_count
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c("v-flex", {
                                                                    attrs: {
                                                                      xs2: ""
                                                                    },
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.getStatusPill(
                                                                          schedule.status
                                                                        )
                                                                      )
                                                                    }
                                                                  }),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "callBackIcon",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.callBackReport(
                                                                                schedule.case_number
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " settings_backup_restore "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            !_vm.schedules[keyName].length
                                              ? [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fadedText"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Op de geselecteerde dag is er geen planning gemaakt."
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "custom-filter", attrs: { shrink: "" } },
            [
              _c("v-text-field", {
                staticClass:
                  "datatable__filter search--input search--input--noBorder",
                attrs: {
                  "append-icon": "search",
                  placeholder: "Zoek naar rapport",
                  "single-line": "",
                  "hide-details": "",
                  clearable: ""
                },
                on: { input: _vm.searchDebounce },
                model: {
                  value: _vm.searchInput,
                  callback: function($$v) {
                    _vm.searchInput = $$v
                  },
                  expression: "searchInput"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "custom-filter", attrs: { shrink: "" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.reportStatusMap,
                  "item-text": "label",
                  "item-value": "key",
                  "hide-details": "",
                  placeholder: "Status",
                  multiple: "",
                  clearable: ""
                },
                on: { change: _vm.getOrganizations },
                model: {
                  value: _vm.selectedStatuses,
                  callback: function($$v) {
                    _vm.selectedStatuses = $$v
                  },
                  expression: "selectedStatuses"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c(
                "v-menu",
                {
                  ref: "dialog",
                  attrs: {
                    "close-on-content-click": false,
                    lazy: "",
                    "nudge-right": "100",
                    "full-width": "",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  model: {
                    value: _vm.isEditingDate,
                    callback: function($$v) {
                      _vm.isEditingDate = $$v
                    },
                    expression: "isEditingDate"
                  }
                },
                [
                  _c("v-text-field", {
                    staticClass: "input input-date mt-3",
                    attrs: {
                      slot: "activator",
                      label: "Filter op datum",
                      "error-messages": _vm.dateErrorMessage,
                      "hide-details": ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.dateFromTextField($event)
                      }
                    },
                    slot: "activator",
                    model: {
                      value: _vm.dateFormatted,
                      callback: function($$v) {
                        _vm.dateFormatted = $$v
                      },
                      expression: "dateFormatted"
                    }
                  }),
                  _c("v-date-picker", {
                    ref: "picker",
                    attrs: {
                      locale: "nl-nl",
                      min: "1910-01-01",
                      color: "#837f16",
                      "first-day-of-week": "1"
                    },
                    on: {
                      change: function($event) {
                        _vm.isEditingDate = false
                      }
                    },
                    model: {
                      value: _vm.date,
                      callback: function($$v) {
                        _vm.date = $$v
                      },
                      expression: "date"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-3": "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _vm._l(_vm.schedules, function(schedule, key) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "planning-checker-" + key,
                          staticClass: "tab__filled",
                          class: { active: _vm.activeTab === key },
                          attrs: { href: "#" + key }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(key) +
                              " (" +
                              _vm._s(_vm.schedules[key].length) +
                              ") "
                          )
                        ]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _vm._l(_vm.schedules, function(schedule, keyName) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key: "planning-checker-organizations-" + keyName,
                              attrs: { lazy: true, value: keyName }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _vm.isLoading
                                        ? _c("LoaderCard", {
                                            attrs: {
                                              type: "spinner--center",
                                              minHeight: "400px"
                                            }
                                          })
                                        : _vm._e(),
                                      !_vm.isLoading && schedule
                                        ? [
                                            _vm.schedules[keyName].length
                                              ? _c(
                                                  "v-layout",
                                                  {
                                                    staticClass:
                                                      "item__list item__list--reports",
                                                    attrs: {
                                                      wrap: "",
                                                      "mb-5": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass: "label",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: { wrap: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs4: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Status"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Aantal"
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._l(
                                                      _vm.foundSchedules(),
                                                      function(
                                                        foundSchedule,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "v-flex",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs4: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    foundSchedule.status
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            domProps: {
                                                                              innerHTML: _vm._s(
                                                                                _vm.getStatusPill(
                                                                                  foundSchedule.status
                                                                                )
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs2: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getStatusCount(
                                                                            foundSchedule.status
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm.schedules[keyName].length
                                              ? _c(
                                                  "v-layout",
                                                  {
                                                    staticClass:
                                                      "item__list item__list--reports",
                                                    attrs: { wrap: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass: "label",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: { wrap: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Datum"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v("Tijd")
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Rapportnummer"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Expert naam"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs1: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Aantal schades"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Status"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs1: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Intrekken"
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._l(
                                                      _vm.schedules[keyName],
                                                      function(
                                                        schedule,
                                                        keyBe
                                                      ) {
                                                        return [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              key: keyBe,
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      schedule.planned_at
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatDate"
                                                                                  )(
                                                                                    schedule.planned_at
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      schedule.planned_at
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "formatTime"
                                                                                  )(
                                                                                    schedule.planned_at
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              schedule.case_number
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              schedule.expert_name
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              schedule.damage_count
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c("v-flex", {
                                                                    attrs: {
                                                                      xs2: ""
                                                                    },
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.getStatusPill(
                                                                          schedule.status
                                                                        )
                                                                      )
                                                                    }
                                                                  }),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs1: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "callBackIcon",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.callBackReport(
                                                                                schedule.case_number
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " settings_backup_restore "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            !_vm.schedules[keyName].length
                                              ? [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fadedText"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Op de geselecteerde dag is er geen planning gemaakt."
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }