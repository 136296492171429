import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';
import { DateTime } from 'luxon';
import { formatDate as format, isValidDate, setFormattedDatePickerValue, dateErrorMessage } from '@/support/String';
import { getStatusColor, getStatusLabel, ReportStatusMap, excludePlanningStatuses } from '@/support/ReportStatus';
import { debounce } from 'lodash';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Rpc } from '@/models/Rpc';

@Component<PlanningChecker>({
  filters: {
    formatDate(date: string) {
      return ! date ? '-' : format(date, 'dd-LL-yyyy');
    },
    formatTime(time: string) {
      return ! time ? '-' : format(time, 'HH:mm');
    },
  },
})
export default class PlanningChecker extends Vue {
  public $pageTitle = 'Planning checker';

  protected activeTab = '';

  protected schedules: {[key: string]: Schedule[]} = {};

  protected reportStatusMap: ReportStatusMap[] = excludePlanningStatuses();

  protected isLoading = false;

  public searchDebounce: Function = this.handleSearch();

  protected searchInput = '';

  protected currentCaseNumber = '';

  protected selectedStatuses: string[] = [];

  protected date = '';

  protected isEditingDate = false;

  public mounted() {
    this.date = DateTime.local().toFormat('yyyy-MM-dd');
    this.emitBreadcrumb();
    this.getOrganizations();
  }

  // DATE
  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected dateFromDatePicker() {
    if (this.date) {
      this.dateFormatted = setFormattedDatePickerValue(this.date, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected dateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
  }

  @Watch('date')
  protected dateChanged() {
    this.dateFromDatePicker();
    this.getOrganizations();
  }

  @Watch('isEditingDate')
  protected isEditingApprovedAtChanged() {
    if (! this.isEditingDate) {
      this.dateFromDatePicker();
    }
  }

  protected getOrganizations() {
    this.isLoading = true;

    const payload = {
      types: ['expert', 'msr'],
      statuses: this.selectedStatuses,
    };

    if (this.dateFormatted) {
      if (! DateTime.fromString(this.dateFormatted, 'dd-LL-yyyy').isValid) {
        this.isLoading = false;
        return;
      }
      (payload as any).schedule_date = this.dateFormatted;
    }

    if (this.searchInput) {
      (payload as any).case_number = this.searchInput;
    }

    new Organization()
      .filter(payload)
      .include('schedule')
      .all()
      .then((organizations: Organization[]) => {
        this.activeTab = organizations && organizations.length && organizations[0].name ? organizations[0].name : '';
        const schedules: any = {};
        organizations.forEach((organization: any) => {
          schedules[organization.name] = organization.schedule;
        });
        this.schedules = schedules;
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected callBackReport(caseNumber: string) {
    this.currentCaseNumber = caseNumber;
    this.$store.dispatch('openDialog', this.dialogOptionsCallBackReport);
  }

  protected get dialogOptionsCallBackReport(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet u zeker dat u dit rapport wilt intrekken ?',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ja, rapport intrekken',
          action: () => {
            const payload: any = {
              signature: 'planning:cancel',
              body: {
                sync: true,
                report: this.currentCaseNumber,
              },
            };

            new Rpc()
              .rpcPost(payload)
              .then((response: any) => {
                this.$store.dispatch('openDialog', this.dialogOptionsCallBackReportSuccess);
                this.getOrganizations();
              })
              .catch((error: AxiosError) => {
                this.$store.dispatch('openDialog', this.dialogOptionsCallBackReportError);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.currentCaseNumber = '';
          },
        },
      },
    };
  }

  protected get dialogOptionsCallBackReportSuccess(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Het rapport is ingetrokken',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }

  protected get dialogOptionsCallBackReportError(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Het rapport kan niet meer worden ingetrokken',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }

  public handleSearch() {
    return debounce((searchInput: string) => {
      this.getOrganizations();
    }, 400);
  }

  protected getStatusCount(key: string) {
    const currentSchedule = this.schedules[this.activeTab];
    if (currentSchedule) {
      const foundSchedules = currentSchedule.filter((schedule: Schedule) => schedule.status === key);
      return foundSchedules ? foundSchedules.length : 0;
    }

    return 0;
  }

  protected foundSchedules() {
    const currentSchedule = this.schedules[this.activeTab];
    const foundSchedules: any = [];

    currentSchedule.forEach((schedule: any) => {
      const existingSchedule = foundSchedules.find((foundSchedule: any) => foundSchedule.status === schedule.status);

      if (! existingSchedule) {
        foundSchedules.push(schedule);
      }
    });

    return foundSchedules;
  }

  protected getStatusLabel(status: string) {
    return getStatusLabel(status);
  }

  protected getStatusPill(key: string) {
    return `<span class="statusPill statusBackgroundColor--${getStatusColor(key)}">${getStatusLabel(key)}</span>`;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Planning checker' },
        ],
      });
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

interface Schedule {
  case_number?: string;
  damage_count?: number;
  expert_name?: string;
  planned_at?: string;
  status?: string;
  uuid?: string;
}
